import React from "react";
import { graphql, HeadFC } from "gatsby";
import Layout from "../components/layout";
import { Trans } from "react-i18next";
import { HeaderSpacer } from "../components/header";
import Form, {
  Input,
  Label,
  Submit,
  Textarea,
  Select,
} from "../components/forms";
import Heading, { headingClass } from "../components/heading";
import { PromoWebshop } from "../components/promo";
import SEO from "../seo";

const CateringForm = () => {
  return (
    <div className="mb-32">
      <HeaderSpacer />
      <h3 id="ask-catering" className={`${headingClass} p-4`}>
        <Trans>Ask for a Quote</Trans>
      </h3>
      <Form
        formsparkId="SK4j4Xej"
        botpoisonKey="pk_aae5e440-8fff-4972-8dc7-9c2a364f1590"
        className="px-4 grid"
        emailSubject="Catering Request"
      >
        <Label htmlFor="first_name" required>
          Name
        </Label>
        <Input
          type="text"
          id="firstName"
          name="first_name"
          placeholder="Type your name"
          required
        />
        <Label htmlFor="last_name" required>
          Last Name
        </Label>
        <Input
          type="text"
          id="lastName"
          name="last_name"
          placeholder="Type your last name"
          required
        />

        <Label htmlFor="companyName">Company Name</Label>
        <Input
          type="text"
          id="companyName"
          name="company_name"
          placeholder="Type your company name"
        />

        <Label htmlFor="vatNumber">VAT Number</Label>
        <Input
          type="text"
          id="vatNumber"
          name="vat_number"
          placeholder="Type your VAT Number e.g. NL999999999B01"
        />

        <Label htmlFor="email" required>
          Email
        </Label>
        <Input
          type="email"
          id="email"
          name="email"
          placeholder="Type your email"
          required
        />

        <Label htmlFor="telephone" required>
          Telephone
        </Label>
        <Input
          type="tel"
          id="telephone"
          name="telephone"
          placeholder="e.g. +31(0)6 01010101"
          required
        />

        <Label htmlFor="eventsDate" required>
          Date of events
        </Label>
        <Input type="date" id="eventsDate" name="date_of_events" required />
        <span className="text-base text-red-500 mb-2">
          {/* prettier-ignore */}
          <Trans>Please allow a minimum of 24 hours notice for large catering orders.</Trans>
        </span>
        <Label htmlFor="eventsTime">Time of events</Label>
        <Input
          type="time"
          id="eventsTime"
          name="time_of_events"
          placeholder=""
        />

        <Label htmlFor="guestsNumber">Number of guests</Label>
        <Input type="number" id="guestsNumber" name="number_of_guests" />

        <Label htmlFor="pickUpOrDelivery">Pick up or Delivery?</Label>
        <Select id="pickUpOrDelivery" name="pick-up_or_delivery">
          <option value="pick-up">
            <Trans>Pick up</Trans>
          </option>
          <option value="delivery">
            <Trans>Delivery</Trans>
          </option>
        </Select>
        <Label htmlFor="deliveryAddress">Address</Label>
        <Input
          type="text"
          id="deliveryAddress"
          name="delivery_address"
          placeholder="It is mandatory only if the delivery options is chosen"
        />

        <Label htmlFor="paymentMethod">Preferred Payment Method</Label>
        <Input
          type="text"
          id="paymentMethod"
          name="payment_method"
          placeholder="Type your payment method. eg. VIA, MASTERCARD, Bank Transfer"
        />

        <Label htmlFor="comments">Comments</Label>
        <Textarea id="comments" name="comments" placeholder="Comments" />
        <Submit>Ask for a Quote</Submit>
      </Form>
    </div>
  );
};

const StoryTell = ({
  title,
  children,
  className = "",
}: {
  title?: string;
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={`${className}`}>
    {title && <Heading>{title}</Heading>}
    <div className="p-4">{children}</div>
  </div>
);

const Catering = () => {
  return (
    <Layout className="!snap-none scroll-pt-32">
      <HeaderSpacer />
      <div className="max-w-screen-lg mx-auto">
        <StoryTell className="bg-yellow-300" title="Catering">
          <p>
            {/* prettier-ignore*/}
            <Trans>Panzerò provides exceptional catering services for any event, big or small! We offer a wide variety of tasty options including some specialty menu items such as our mini panzerotti and our irresistible bruschetta with organic Stracciatella cheese to add a bit of extra flair to your special day.</Trans>
          </p>
        </StoryTell>
        <div
          className="w-full h-[40vh] relative"
          style={{
            background: "center center / cover url(/catering/1_catering.jpg)",
          }}
        />
        <p className="p-4 text-lg bg-white backdrop-blur-sm">
          {/* prettier-ignore*/}
          <Trans>Customize your own Panzerò catering experience today! All Panzerò catering orders serve a minimum of 20 people.</Trans>
        </p>
        <div
          className="w-full h-[50vh]"
          style={{
            background: "center center / cover url(/catering/2_catering.jpg)",
          }}
        />
        <p className="p-4 text-lg">{/* prettier-ignore*/}</p>
        <div
          className="w-full h-[50vh]"
          style={{
            background: "center center / cover url(/catering/3_party.jpg)",
          }}
        />
        <p className="p-4 text-lg">
          {/* prettier-ignore*/}
          <Trans><a href="https://www.order-panzero.shop" className="bg-yellow-500 px-1">Please place your order here</a> for any group order that is less than 20 people</Trans>
        </p>
        <p className="p-4 text-lg">
          {/* prettier-ignore*/}
          <Trans>Otherwise request a personalized quote today by filling the form here</Trans>
          <a href="#ask-catering" className="text-lg p-4">
            <span className="bg-yellow-500 px-1">
              <Trans>Request a Quote</Trans>
            </span>
          </a>
        </p>
        <div
          className="w-full h-[50vh]"
          style={{
            background: "center center / cover url(/catering/4_catering.jpg)",
          }}
        />
        <div className="p-4">
          <h4 className="text-xl text-red-500">
            <Trans>Hire Panzerò to cater your next gathering!</Trans>
          </h4>
          <p>
            {/* prettier-ignore*/}
            <Trans>From the simplest meal delivery to a more refined service and setup, Panzerò has you covered! For any occasion, we’ll be happy to put together a catered affair tailored to your specific needs.</Trans>
          </p>
        </div>
        <div className="p-4">
          <h4 className="text-xl text-red-500">
            <Trans>Panzerò’s premium catering</Trans>
          </h4>
          <p>
            {/* prettier-ignore*/}
            <Trans>Reduce the hassle and enjoy your event from beginning to end with Panzerò’s premium catering package! Our staff will set up, serve, bartend, and clean up so you can focus on having a great time!</Trans>
          </p>
        </div>
        <div className="p-4">
          <h4 className="text-xl text-red-500">
            <Trans>Large Catering Orders</Trans>
          </h4>
          <p>
            {/* prettier-ignore*/}
            <Trans>Catering orders for 50 or more people require a little extra love. For these larger orders, the food will need to be warmed before serving to your guests. We will provide all the necessary instructions for storing and heating the food so that it’s Panzerò perfection for when you’re ready to serve it!</Trans>
          </p>
        </div>
        <div
          className="w-full h-[50vh]"
          style={{
            background: "center center / cover url(/catering/5_delivery.jpg)",
          }}
        />

        <div className="p-4">
          <h4 className="text-xl text-red-500">
            <Trans>Delivery Fees</Trans>
          </h4>
          <p>
            {/* prettier-ignore*/}
            <Trans>You’re always welcome to pick up your catering order yourself or we can deliver it right to your door! Deliveries up to 4 kilometers away cost 10€. Any deliveries 5 - 40 kilometers away will be 25€. Over 40 kilometers will be quoted in the offer</Trans>
          </p>
        </div>
        <div className="p-4">
          <h4 className="text-xl text-red-500">
            <Trans>Catering Cancellation Policy</Trans>
          </h4>
          <p>
            {/* prettier-ignore*/}
            <Trans>If you need to cancel your catering order for any reason please do so as soon as possible. If your catering order is for 50 or more people, you can cancel 24 hours or more in advance of the event to avoid paying 100% of the catering cost. If your party is smaller than 20 people and you cancel within six hours or more of the event starting time you can avoid paying the penalty of 50% of your catering order. Any party that does not follow these Panzerò catering guidelines will be charged the above-mentioned fees through an invoice with a payment link embed or iDeal payment.</Trans>
          </p>
        </div>
        <CateringForm />
      </div>
      <PromoWebshop />
    </Layout>
  );
};

export default Catering;

export const Head: HeadFC = () => (
  <SEO title="Catering">
    <script src="https://unpkg.com/@botpoison/browser" async />
  </SEO>
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
